/** @format */

import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

export async function sendEmailToHubspot(email: string): Promise<void> {
  await axios.post(
    `https://api.hsforms.com/submissions/v3/integration/submit/7307394/430e913d-24df-479b-a97f-4b416324269f`,
    { fields: [{ name: 'email', value: email }] },
    { headers: { 'Content-Type': 'application/json' } },
  );
}

// returns user_group_id
export async function getOrCreateCustomer(): Promise<string> {
  const provided_id = uuidv4();
  return axios
    .post(
      `https://api.explo.co/api/create_customer/`,
      {
        name: provided_id,
        provided_id: provided_id,
        is_demo_group: false,
        parent_provided_id: '1',
        hierarchy_level_name: 'User',
      },
      {
        headers: {
          'Explo-Authorization': `Token d343f9c2b6f2aea5f2227d9c818ea154f1b288947074a6bdd492d9e63c36215e`,
          Accept: 'application/json',
        },
      },
    )
    .then((res: any) => {
      return res.data.customer.token;
    });
}
